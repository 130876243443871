import Login from './components/Login';
import All from './components/All';
import Client from './components/All/Client';
import Server from './components/All/Server';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/client" element={<All />} /> */}
        {/* <Route path="/server" element={<All />} /> */}
        <Route path="/client" element={<Client />} />
        <Route path="/server" element={<Server />} />
      </Routes>
    </>
  );
}

export default App;
