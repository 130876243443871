import { Table, Button, Modal, Input, Form, message, } from 'antd';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import NewPublicCard from '../NewPublicCard';

export default function NewClient() {

    // 获取token
    const token = localStorage.getItem('token')

    const [isOpenCca, setIsOpenCca] = useState(false); // 配置按钮的modal框
    const [isOpenClientUpdate, setIsOpenClientUpdate] = useState(false); // 更改按钮的modal框
    const [ownRecord, setOwnRecord] = useState({}); // 存放record
    const [ccaList, setCcaList] = useState([])
    // 配置按钮
    const handleCancelCca = () => {
        setIsOpenCca(false);
    };

    // 更改按钮
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('horizontal');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;

    const buttonItemLayout =
        formLayout === 'horizontal'
            ? {
                wrapperCol: {
                    span: 14,
                    offset: 4,
                },
            }
            : null;


    // 创建一个客户端信息
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clientInsertMessage, setClientInsertMessage] = useState('')

    const createClientValue = useRef()

    const createClient = () => {
        setIsModalOpen(true);
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getClientInsert = (data) => {
        axios({
            url: 'api/client/insert',
            method: 'post',
            headers: { token: token },
            data: { name: data }
        }).then(res => {
            if (res.data.code === 200) {
                setClientInsertMessage('创建成功')
                window.location.reload()
            } else {
                const message = res.data.message
                setClientInsertMessage(message)
            }
        }, err => {
            console.log(err);
            setClientInsertMessage('请检查你输入的内容')
        });

    }


    // 储存客户端列表数据
    const [data, setData] = useState([])

    // 表头
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '别名',
            dataIndex: 'alias',
            key: 'alias',
        },
        {
            title: 'mac地址',
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: 'accessKey',
            dataIndex: 'accessKey',
            key: 'accessKey',
        },
        {
            title: 'secretKey',
            dataIndex: 'secretKey',
            key: 'secretKey',
        },
        {
            title: '最后活跃时间',
            dataIndex: 'lastActiveTime',
            key: 'lastActiveTime',
        },
        {
            title: '更多',
            dataIndex: 'more',
            key: 'more',
            render: (_, record) =>
                <>
                    <Button onClick={(event) => {
                        if (event.isTrusted) {
                            setOwnRecord(record)
                            bbbb(record)
                            setIsOpenCca(true)
                        }
                    }} >配置</Button>
                    <Modal open={isOpenCca} onCancel={handleCancelCca} footer={[]}  >
                        <NewPublicCard record={ownRecord} ccaList={ccaList} />
                    </Modal>
                    <Button onClick={(event) => {
                        if (event.isTrusted) {
                            setOwnRecord(record)
                            setIsOpenClientUpdate(true)
                        }
                    }} >更改</Button>
                    <Modal
                        title="请填写要更改的信息"
                        open={isOpenClientUpdate}
                        onCancel={() => { setIsOpenClientUpdate(false) }}
                        footer={[
                        ]}
                    >
                        <Form
                            {...formItemLayout}
                            layout={formLayout}
                            form={form}
                            initialValues={{
                                layout: formLayout,
                            }}
                            onValuesChange={onFormLayoutChange}
                            style={{
                                maxWidth: 600,
                            }}
                            onFinish={(value) => { console.log(value) }}
                        >
                            <Form.Item label="新别名">
                                <Input maxLength={64} placeholder="请输入新别名（最多输入64位）" ref={newAlias} />
                            </Form.Item>
                            <Form.Item label="新mac地址">
                                <Input maxLength={32} placeholder="请输入新mac地址（最多输入32位）" ref={newMacAddress} />
                            </Form.Item>
                            <Form.Item label="accessKey">
                                <Input maxLength={1000} placeholder="请输入accessKey（最多输入1000位）" ref={newAccessKey} />
                            </Form.Item>
                            <Form.Item label="secretKey">
                                <Input maxLength={1000} placeholder="请输入secretKey（最多输入1000位）" ref={newSecretKey} />
                            </Form.Item>
                            <Form.Item {...buttonItemLayout}>
                                <Button type="primary" onClick={e => { e.stopPropagation(); updateButton(ownRecord); }} >确认更改</Button>
                            </Form.Item>
                        </Form>

                    </Modal>

                    <Button onClick={(event) => {
                        if (event.isTrusted) {
                            deleteButton(record)
                        }
                    }} >删除</Button>
                </>,
        },
    ];

    // 获取客户端列表
    useEffect(() => {

        axios({
            url: '/api/client/list',
            method: 'get',
            headers: { token: token },
        }).then(res => {
            if (res.data.code === 200) {
                const clientList = res.data.data
                setData(clientList)
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });




    }, [])

    console.log('token', token)

    const bbbb = (record) => {
        // 获取客户端频道类型的配置项列表
        axios({
            url: '/api/cca/list',
            method: 'get',
            headers: { token: token },
            params: { clientId: record.id }
        }).then(res => {
            if (res.data.code === 200) {
                const list = res.data.data
                setCcaList(list)
                // setCcaList(list)
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });

    }




    // 配置按钮

    // 更改按钮

    const newAlias = useRef(null);
    const newMacAddress = useRef(null);
    const newAccessKey = useRef(null);
    const newSecretKey = useRef(null);

    const updateButton = (record) => {
        const data = {
            id: parseInt(record.id),
            alias: newAlias.current.input.value ? newAlias.current.input.value : null,
            macAddress: newMacAddress.current.input.value ? newMacAddress.current.input.value : null,
            accessKey: newAccessKey.current.input.value ? newAccessKey.current.input.value : null,
            secretKey: newSecretKey.current.input.value ? newSecretKey.current.input.value : null,
        }
        if (data.alias || data.macAddress || data.accessKey || data.secretKey) {
            axios({
                url: '/api/client/update',
                method: 'post',
                headers: { token: token },
                data
            }).then(res => {
                if (res.data.code === 200) {
                    console.log(res.data.data)
                    alert('更改成功')
                    window.location.reload()
                } else {
                    console.log(res.data.message)
                    alert(res.data.message)
                }
            }, err => {
                console.log(err);
            });
        } else setIsOpenClientUpdate(false)
    }

    // 删除按钮
    const deleteButton = (record) => {
        const id = record.id
        axios({
            url: '/api/client/delete',
            method: 'post',
            headers: { token: token },
            data: { id }
        }).then(res => {
            if (res.data.code === 200) {
                console.log(res.data.data)
                window.location.reload()
                alert('删除成功')
            } else {
                console.log(res.data.data)
                window.location.reload()
                alert('删除失败')
            }
        }, err => {
            console.log(err);
        });

    }

    return (
        <>
            {/* 创建一个客户端信息 */}
            <Button onClick={createClient} style={{ marginBottom: 10 }} >创建一个客户端信息</Button>
            <Modal
                title="请填写客户端主机名"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[]}
            >
                <Input maxLength={64}
                    ref={createClientValue}
                    placeholder='最多输入64位'
                    onPressEnter={
                        e => {
                            const value = e.target.value;
                            getClientInsert(value);
                            setIsModalOpen(false);
                        }}></Input>
                <Button style={{
                    position: 'relative',
                    left: '50%',
                    top: '10px',
                    transform: 'translateX(-50%)',
                }} onClick={() => {
                    const value = createClientValue.current.input.value
                    getClientInsert(value)
                    setIsModalOpen(false);
                }} >确定</Button>
            </Modal>

            {/* <NewClientCard data={data} /> */}
            <Table columns={columns} dataSource={data} pagination={false} />
        </>
    )

}