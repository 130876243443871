import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './index.css'
import dayjs from 'dayjs';

import { Button, Space, Table, Tag, Modal, DatePicker, Input } from 'antd';

export default function Monitor(props) {

    const token = localStorage.getItem('token')

    const [data, setData] = useState([])  // 储存表格数据
    const [open, setOpen] = useState(false); // 历史状态的模态框
    const [logListData, setLogListData] = useState([])  // 储存历史状态数据
    const [myRecord, setMyRecord] = useState()
    const [openServerUpdata, setOpenServerUpdata] = useState(false); // 更新服务端的模态框

    const serverUpdataValue = useRef() // 更新服务端的新别名



    // 表头
    const columns = [
        {
            title: '服务器名',
            dataIndex: 'serviceName',
            key: 'serviceName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '主机',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '别名',
            dataIndex: 'alias',
            key: 'alias',
        },
        {
            title: '主机唯一标识码',
            dataIndex: 'macAddress',
            key: 'macAddress',
        },
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '最后活跃时间',
            dataIndex: 'lastActiveTime',
            key: 'address',
        },
        {
            title: '更多',
            dataIndex: 'more',
            key: 'more',
            render: (_, record) =>
                record.ephemeral
                    ?
                    <Button onClick={(event) => {
                        if (event.isTrusted) {
                            serverInsert(record)
                        }
                    }} >持久化</Button>
                    : <>
                        <Button onClick={(event) => {
                            if (event.isTrusted) {
                                setMyRecord(record)
                                showModal(record)
                            }
                        }}> 查看历史状态</Button>
                        <Modal
                            title="历史状态"
                            open={open}
                            // onOk={handleOk}
                            onCancel={handleCancel}
                            footer={[]}
                        >
                            <Table
                                columns={logListcolumns}
                                dataSource={logListData}
                                pagination={false}
                                scroll={{
                                    y: 400,
                                }} />
                            <DatePicker defaultValue={dayjs(day, dateFormat)}
                                format={dateFormat}
                                onChange={(_, dateString) => {

                                    const serverLogData = {
                                        serverId: myRecord.id,
                                        date: dateString,
                                    }

                                    serverLogList(serverLogData)
                                }}
                                style={{ position: 'relative', left: 300, top: 15 }} />
                        </Modal>
                        <Button onClick={(event) => {
                            if (event.isTrusted) {
                                serverDelete(record)
                            }
                        }} >解除持久化</Button>
                        <Button onClick={(event) => {
                            if (event.isTrusted) {
                                // serverDelete(record)
                                setMyRecord(record)
                                setOpenServerUpdata(true)
                            }
                        }}>修改</Button>
                        <Modal
                            title='请填写服务端别名'
                            open={openServerUpdata}
                            onOk={handleOkServerUpdata}
                            onCancel={handleCancelServerUpdata}
                            footer={[]} >
                            <Input maxLength={64}
                                ref={serverUpdataValue}
                                placeholder='最多输入64位'
                                onPressEnter={
                                    e => {
                                        const value = e.target.value;
                                        serverUpdate(value)
                                        setOpenServerUpdata(false);
                                    }}></Input>
                            <Button style={{
                                position: 'relative',
                                left: '50%',
                                top: '10px',
                                transform: 'translateX(-50%)',
                            }} onClick={() => {
                                const value = serverUpdataValue.current.input.value
                                serverUpdate(value)
                                setOpenServerUpdata(false);
                            }} >确定</Button>
                        </Modal>
                    </>
            // <>
            // </>,
        },
    ];


    useEffect(() => {
        // 获取表单数据
        axios({
            url: '/api/server/list',
            method: 'get',
            headers: { token: token },
            params: null
        }).then(res => {
            if (res.data.code === 200) {
                const newData = res.data.data
                setData(newData)
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });
    }, [])


    // 查看历史状态
    const serverLogList = (data) => {
        axios({
            url: '/api/serverLog/list',
            method: 'get',
            headers: { token: token },
            params: data
        }).then(res => {
            if (res.data.code === 200) {
                const list = res.data.data
                setLogListData(list)
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });
    }

    // 历史状态的框
    const showModal = (record) => {
        const serverLogData = {
            serverId: record.id,
            date: day
        }
        serverLogList(serverLogData)
        setOpen(true);
    };
    const handleOk = (e) => {
        setOpen(false);
    };
    const handleCancel = (e) => {
        setOpen(false);
    };

    // 右下角的日期选择

    const date = dayjs();
    const day = date.format('YYYY-MM-DD');
    console.log('day', day)
    const dateFormat = 'YYYY-MM-DD';
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    // 历史状态的表头
    const logListcolumns = [
        {
            title: '状态',
            dataIndex: 'statusName',
            key: 'statusName',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '描述',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '时间',
            dataIndex: 'insertTime',
            key: 'insertTime',
            width: 170,
        },
    ];

    // 持久化一个线上服务
    const serverInsert = (record) => {
        const data = {
            name: record.name,
            serviceName: record.serviceName,
            macAddress: record.macAddress,
        }
        axios({
            url: '/api/server/insert',
            method: 'post',
            headers: { token: token },
            data,
        }).then(res => {
            if (res.data.code === 200) {
                const message = res.data.message
                console.log(message)
                window.location.reload()
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });

    }

    // 取消一个线上服务的持久化
    const serverDelete = (record) => {
        const id = record.id
        axios({
            url: '/api/server/delete',
            method: 'post',
            headers: { token: token },
            data: { id }
        }).then(res => {
            if (res.data.code === 200) {
                const message = res.data.message
                console.log(message)
                window.location.reload()
            } else {
                // setClientInsertMessage(message)
            }
        }, err => {
            console.log(err);
        });

    }

    // 更新服务端
    const serverUpdate = (value) => {
        const data = {
            id: myRecord.id,
            alias: value
        }
        axios({
            url: '/api/server/update',
            method: 'post',
            headers: {
                "Content-Type": "application/json",
                token: token
            },
            data,
        }).then(res => {
            if (res.data.code === 200) {
                const message = res.data.message
                console.log(message)
                window.location.reload()
            } else {
                console.log(res)
            }
        }, err => {
            console.log(err);
        });
    }

    // 更新服务端的框
    const handleOkServerUpdata = (e) => {
        setOpenServerUpdata(false);
    };
    const handleCancelServerUpdata = (e) => {
        setOpenServerUpdata(false);
    };


    return (
        <Table columns={columns} dataSource={data} pagination={false} rowClassName={(record, index) => {
            return (
                record.ephemeral ? 'a' : 'b'
            )
        }} />
    )
}