import { Card, Button } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Monitor from '../Monitor';
import NewClient from '../NewClient';
import { refreshToken } from '../../Utils/axios';


const All = (props) => {

    const tab = props.tab

    const tab1 = () => {
        return (
            <Link to="/client"  >
                <p style={{ color: 'black' }}  >客户端配置</p>
            </Link>
        )
    }

    const tab2 = () => {
        return (
            <Link to="/server"  >
                <p style={{ color: 'black' }}  >服务端监控</p>
            </Link>
        )
    }


    const tabList = [
        {
            key: 'tab1',
            tab: tab1()
        },
        {
            key: 'tab2',
            tab: tab2(),
        },
    ];
    const contentList = {
        tab1: <NewClient />,
        tab2: <Monitor />,
    };

    return (
        <>
            <Card
                style={{
                    width: '100%',
                }}
                tabBarExtraContent={<a onClick={refreshToken} >刷新token</a>}
                tabList={tabList}
                activeTabKey={tab}
            >
                {contentList[tab]}
            </Card>
        </>
    )

}

export default All;