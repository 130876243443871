import { Button, Card, Modal, Input, Form, InputNumber, Space, Switch, Select } from 'antd';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { allowSingleEnum } from '../../Utils/Enum';
import { type } from '@testing-library/user-event/dist/type';

export default function NewPublicCard(props) {

    // 获取token
    const token = localStorage.getItem('token')
    const [modifyopen, setmodifyopen] = useState(false)

    const [activeTabKey2, setActiveTabKey2] = useState('');
    const [tabListNoTitle, setTabListNoTitle] = useState([]);  // 表头
    const [contentListNoTitle, setContentListNoTitle] = useState([]);  // 内容
    const [publicAccountDisabled, setPublicAccountDisabled] = useState(false)
    const [tikTokDisabled, setTikTokDisabled] = useState(false)
    const [videoDisabled, setVideoDisabled] = useState(false)
    const [learningPowerDisabled, setLearningPowerDisabled] = useState(false)

    const ccaList = props.ccaList

    const modifyModal = () => {
        setmodifyopen(true)
    }

    const aa = () => {

        const tabListNoTitle = []  // 表头
        const contentListNoTitle = {} // 内容
        // 把表头和内容重置为空！
        setTabListNoTitle([])
        setContentListNoTitle([])
        ccaList.map((item, index) => {

            switch (item.channelType) {
                case 1:
                    tabListNoTitle.push({
                        key: 'tikTok',
                        tab: '抖音',
                    })
                    contentListNoTitle.tikTok = <>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <h4>客户端配置</h4>
                                <Button onClick={(event) => {
                                    if (event.isTrusted) {
                                        modifyModal();
                                        // setIsModalOpen(true)
                                    }
                                }}>修改</Button>
                                <Modal
                                    open={modifyopen}
                                    title="请填写要修改的频道配置内容"
                                    // open={isModalOpen}
                                    onCancel={(event) => {
                                        if (event.isTrusted) { setmodifyopen(false) }
                                    }}
                                    footer={[
                                    ]}>

                                    <Form
                                        {...formItemLayout}
                                        layout={formLayout}
                                        form={form}
                                        initialValues={{
                                            layout: formLayout,
                                        }}
                                        onValuesChange={onFormLayoutChange}
                                        validateMessages={validateMessages}
                                        style={{
                                            maxWidth: 600,
                                        }}
                                    >
                                        <Form.Item label="同时工作数量">
                                            <InputNumber min={-2147483648} max={2147483647} defaultValue={item.channelSize} placeholder="例如：1(不填即为不限制）" style={{ width: 280 }} ref={channelSize} />
                                        </Form.Item>
                                        <Form.Item label="可提交任务数量">
                                            <InputNumber defaultValue={item.taskCount} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={taskCount} />
                                        </Form.Item>
                                        <Form.Item label="过期时间">
                                            <Input maxLength={32} defaultValue={item.expireTime} placeholder="例如：2023-01-12T10:01:00（限32位）" ref={expireTime} />
                                        </Form.Item>
                                        <Form.Item label="优先级"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber defaultValue={item.priority} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={priority} />
                                        </Form.Item>
                                        <Form.Item label="允许单个新媒体服务请求">
                                            <Switch style={{ marginLeft: 20 }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={single} ref={single} />
                                        </Form.Item>
                                        <Form.Item {...buttonItemLayout}>
                                            <Button type="primary" onClick={(event) => {
                                                if (event.isTrusted) {
                                                    getCcaUpdate(item.id)
                                                }
                                            }}>确定</Button>
                                        </Form.Item>
                                    </Form>


                                </Modal>
                            </div>
                            <div>
                                <p> 可同时工作频道数量限制:{item.channelSize ? item.channelSize : '不限制'}</p>
                                <p> 频道总提交任务数量限制:{item.taskCount ? item.taskCount : '不限制'}</p>
                                <p> 该类新媒体服务过期时间:{item.expireTime ? item.expireTime : '不限制'}</p>
                                <p> 优先级:{item['priority']}</p>
                                <p> 是否允许爬取单个:{allowSingleEnum[item['allowSingle']]}</p>
                            </div>
                        </div>
                        <div style={{ width: '100%', border: '1px solid rgba(34, 1, 1, 0.107)' }} ></div>
                        <div>
                            <div>
                                <h4>客户端数据</h4>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>该客户端该类频道已经提交任务数量：{item['provideTaskCount']}</p>
                                    <Button onClick={(event) => {
                                        if (event.isTrusted) { getCcaReset(item.id) }
                                    }} >重置</Button>
                                </div>
                                <p>该客户端该类频道总数：{item['channelCount']}</p>
                                <p>该客户端该类频道同时工作数量：{item['activeChannelCount']}</p>
                                <p>该客户端该类频道最后工作时间：{item['lastActiveTime']}</p>
                            </div>
                        </div>
                    </>
                    break;
                case 3:
                    tabListNoTitle.push({
                        key: 'publicAccount',
                        tab: '公众号',
                    })
                    contentListNoTitle.publicAccount = <>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <h4>客户端配置</h4>
                                <Button onClick={(event) => {
                                    if (event.isTrusted) {
                                        modifyModal();
                                        // setIsModalOpen(true)
                                    }
                                }}>修改</Button>
                                <Modal
                                    open={modifyopen}
                                    title="请填写要修改的频道配置内容"
                                    // open={isModalOpen}
                                    onCancel={(event) => {
                                        if (event.isTrusted) { setmodifyopen(false) }
                                    }}
                                    footer={[
                                    ]}>

                                    <Form
                                        {...formItemLayout}
                                        layout={formLayout}
                                        form={form}
                                        initialValues={{
                                            layout: formLayout,
                                        }}
                                        onValuesChange={onFormLayoutChange}
                                        style={{
                                            maxWidth: 600,
                                        }}
                                    >
                                        <Form.Item label="同时工作数量">
                                            <InputNumber min={-2147483648} max={2147483647} defaultValue={item.channelSize} placeholder="例如：1(不填即为不限制）" style={{ width: 280 }} ref={channelSize} />
                                        </Form.Item>
                                        <Form.Item label="可提交任务数量">
                                            <InputNumber defaultValue={item.taskCount} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={taskCount} />
                                        </Form.Item>
                                        <Form.Item label="过期时间">
                                            <Input maxLength={32} defaultValue={item.expireTime} placeholder="例如：2023-01-12T10:01:00（限32位）" ref={expireTime} />
                                        </Form.Item>
                                        <Form.Item label="优先级"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber defaultValue={item.priority} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={priority} />
                                        </Form.Item>
                                        <Form.Item label="允许单个新媒体服务请求">
                                            <Switch style={{ marginLeft: 20 }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked ref={single} />
                                        </Form.Item>
                                        <Form.Item {...buttonItemLayout}>
                                            <Button type="primary" onClick={(event) => {
                                                if (event.isTrusted) {
                                                    getCcaUpdate(item.id)
                                                }
                                            }}>确定</Button>
                                        </Form.Item>
                                    </Form>


                                </Modal>
                            </div>
                            <div>
                                <p> 可同时工作频道数量限制:{item.channelSize}</p>
                                <p> 频道总提交任务数量限制:{item.taskCount}</p>
                                <p> 该类新媒体服务过期时间:{item.expireTime}</p>
                                <p> 优先级:{item['priority']}</p>
                                <p> 是否允许爬取单个:{allowSingleEnum[item['allowSingle']]}</p>
                            </div>
                        </div>
                        <div style={{ width: '100%', border: '1px solid rgba(34, 1, 1, 0.107)' }} ></div>
                        <div>
                            <div>
                                <h4>客户端数据</h4>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>该客户端该类频道已经提交任务数量：{item['provideTaskCount']}</p>
                                    <Button onClick={(event) => {
                                        if (event.isTrusted) { getCcaReset(item.id) }
                                    }}>重置</Button>
                                </div>
                                <p>该客户端该类频道总数：{item['channelCount']}</p>
                                <p>该客户端该类频道同时工作数量：{item['activeChannelCount']}</p>
                                <p>该客户端该类频道最后工作时间：{item['lastActiveTime']}</p>
                            </div>
                        </div>
                    </>
                    break;
                case 4:
                    tabListNoTitle.push({
                        key: 'videoNumber',
                        tab: '视频号',
                    })
                    contentListNoTitle.videoNumber = <>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <h4>客户端配置</h4>
                                <Button onClick={(event) => {
                                    if (event.isTrusted) {
                                        modifyModal();
                                        // setIsModalOpen(true)
                                    }
                                }}>修改</Button>
                                <Modal
                                    open={modifyopen}
                                    title="请填写要修改的频道配置内容"
                                    // open={isModalOpen}
                                    onCancel={(event) => {
                                        if (event.isTrusted) { setmodifyopen(false) }
                                    }}
                                    footer={[
                                    ]}>

                                    <Form
                                        {...formItemLayout}
                                        layout={formLayout}
                                        form={form}
                                        initialValues={{
                                            layout: formLayout,
                                        }}
                                        onValuesChange={onFormLayoutChange}
                                        style={{
                                            maxWidth: 600,
                                        }}
                                    >
                                        <Form.Item label="同时工作数量">
                                            <InputNumber min={-2147483648} max={2147483647} defaultValue={item.channelSize} placeholder="例如：1(不填即为不限制）" style={{ width: 280 }} ref={channelSize} />
                                        </Form.Item>
                                        <Form.Item label="可提交任务数量">
                                            <InputNumber defaultValue={item.taskCount} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={taskCount} />
                                        </Form.Item>
                                        <Form.Item label="过期时间">
                                            <Input maxLength={32} defaultValue={item.expireTime} placeholder="例如：2023-01-12T10:01:00（限32位）" ref={expireTime} />
                                        </Form.Item>
                                        <Form.Item label="优先级"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber defaultValue={item.priority} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={priority} />
                                        </Form.Item>
                                        <Form.Item label="允许单个新媒体服务请求">
                                            <Switch style={{ marginLeft: 20 }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked ref={single} />
                                        </Form.Item>
                                        <Form.Item {...buttonItemLayout}>
                                            <Button type="primary" onClick={(event) => {
                                                if (event.isTrusted) {
                                                    getCcaUpdate(item.id)
                                                }
                                            }}>确定</Button>
                                        </Form.Item>
                                    </Form>


                                </Modal>
                            </div>
                            <div>
                                <p> 可同时工作频道数量限制:{item.channelSize}</p>
                                <p> 频道总提交任务数量限制:{item.taskCount}</p>
                                <p> 该类新媒体服务过期时间:{item.expireTime}</p>
                                <p> 优先级:{item['priority']}</p>
                                <p> 是否允许爬取单个:{allowSingleEnum[item['allowSingle']]}</p>
                            </div>
                        </div>
                        <div style={{ width: '100%', border: '1px solid rgba(34, 1, 1, 0.107)' }} ></div>
                        <div>
                            <div>
                                <h4>客户端数据</h4>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>该客户端该类频道已经提交任务数量：{item['provideTaskCount']}</p>
                                    <Button onClick={(event) => {
                                        if (event.isTrusted) { getCcaReset(item.id) }
                                    }}>重置</Button>
                                </div>
                                <p>该客户端该类频道总数：{item['channelCount']}</p>
                                <p>该客户端该类频道同时工作数量：{item['activeChannelCount']}</p>
                                <p>该客户端该类频道最后工作时间：{item['lastActiveTime']}</p>
                            </div>
                        </div>
                    </>
                    break;
                case 5:
                    tabListNoTitle.push({
                        key: 'learningPower',
                        tab: '学习强国',
                    })
                    contentListNoTitle.learningPower = <>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <h4>客户端配置</h4>
                                <Button onClick={(event) => {
                                    if (event.isTrusted) {
                                        modifyModal();
                                        // setIsModalOpen(true)
                                    }
                                }}>修改</Button>
                                <Modal
                                    open={modifyopen}
                                    title="请填写要修改的频道配置内容"
                                    // open={isModalOpen}
                                    onCancel={(event) => {
                                        if (event.isTrusted) { setmodifyopen(false) }
                                    }}
                                    footer={[
                                    ]}>

                                    <Form
                                        {...formItemLayout}
                                        layout={formLayout}
                                        form={form}
                                        initialValues={{
                                            layout: formLayout,
                                        }}
                                        onValuesChange={onFormLayoutChange}
                                        style={{
                                            maxWidth: 600,
                                        }}
                                    >
                                        <Form.Item label="同时工作数量">
                                            <InputNumber min={-2147483648} max={2147483647} defaultValue={item.channelSize} placeholder="例如：1(不填即为不限制）" style={{ width: 280 }} ref={channelSize} />
                                        </Form.Item>
                                        <Form.Item label="可提交任务数量">
                                            <InputNumber defaultValue={item.taskCount} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={taskCount} />
                                        </Form.Item>
                                        <Form.Item label="过期时间">
                                            <Input maxLength={32} defaultValue={item.expireTime} placeholder="例如：2023-01-12T10:01:00（限32位）" ref={expireTime} />
                                        </Form.Item>
                                        <Form.Item label="优先级"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <InputNumber defaultValue={item.priority} placeholder="例如：1(不填即为不限制）" min={0} style={{ width: 280 }} ref={priority} />
                                        </Form.Item>
                                        <Form.Item label="允许单个新媒体服务请求">
                                            <Switch style={{ marginLeft: 20 }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked ref={single} />
                                        </Form.Item>
                                        <Form.Item {...buttonItemLayout}>
                                            <Button type="primary" onClick={(event) => {
                                                if (event.isTrusted) {
                                                    getCcaUpdate(item.id)
                                                }
                                            }}>确定</Button>
                                        </Form.Item>
                                    </Form>


                                </Modal>
                            </div>
                            <div>
                                <p> 可同时工作频道数量限制:{item.channelSize}</p>
                                <p> 频道总提交任务数量限制:{item.taskCount}</p>
                                <p> 该类新媒体服务时间:{item.expireTime}</p>
                                <p> 优先级:{item['priority']}</p>
                                <p> 是否允许爬取单个:{allowSingleEnum[item['allowSingle']]}</p>
                            </div>
                        </div>
                        <div style={{ width: '100%', border: '1px solid rgba(34, 1, 1, 0.107)' }} ></div>
                        <div>
                            <div>
                                <h4>客户端数据</h4>
                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>该客户端该类频道已经提交任务数量：{item['provideTaskCount']}</p>
                                    <Button onClick={(event) => {
                                        if (event.isTrusted) { getCcaReset(item.id) }
                                    }}>重置</Button>
                                </div>
                                <p>该客户端该类频道总数：{item['channelCount']}</p>
                                <p>该客户端该类频道同时工作数量：{item['activeChannelCount']}</p>
                                <p>该客户端该类频道最后工作时间：{item['lastActiveTime']}</p>
                            </div>
                        </div>
                    </>
                    break;
            }
            setTabListNoTitle(tabListNoTitle)
            setContentListNoTitle(contentListNoTitle)
        })
    }

    const onTab2Change = (key) => {
        setActiveTabKey2(key);
    };


    // 新增客户端频道配置

    const isDisabled = () => {
        const record = props.record
        // 设置新增频道配置那里是否可选

        // 先重新再设置为不禁止
        setTikTokDisabled(false)
        setPublicAccountDisabled(false)
        setVideoDisabled(false)
        setLearningPowerDisabled(false)

        ccaList.map((item, index) => {
            if (item.channelType === 1) {
                setTikTokDisabled(true)
            }
            if (item.channelType === 3) {
                setPublicAccountDisabled(true)
            }
            if (item.channelType === 4) {
                setVideoDisabled(true)
            }
            if (item.channelType === 5) {
                setLearningPowerDisabled(true)
            }
        })
        ccaInsertData.clientId = record.id
    }


    const getCcaInsert = (data) => {
        axios({
            url: '/api/cca/insert',
            method: 'post',
            headers: { token: token },
            data,
        }).then(res => {
            if (res.data.code === 200) {
                console.log('ok')
                window.location.reload()
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });
    }

    const ccaInsertData = {}
    const handleChange = (value) => {
        ccaInsertData.channelType = value
    };


    // 修改客户端频道配置

    // 修改频道配置的表单
    // const [single, setSingle] = useState(true)


    const channelSize = useRef(null);
    const taskCount = useRef(null);
    const expireTime = useRef(null);
    const priority = useRef(null);
    const single = useRef(true)

    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('horizontal');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };

    const formItemLayout =
        formLayout === 'horizontal'
            ? {
                labelCol: {
                    span: 8,
                },
                wrapperCol: {
                    span: 14,
                },
            }
            : null;
    const buttonItemLayout =
        formLayout === 'horizontal'
            ? {
                wrapperCol: {
                    span: 14,
                    offset: 4,
                },
            }
            : null;

    const validateMessages = {
        // required: '${label} is required!',
        types: {
            number: '${label} 不是数字!',
        },
        number: {
            range: '${label} 必须在 ${min} 和 ${max} 之间',
        },
    };

    const getCcaUpdate = (id) => {
        const data = {
            id,
            priority: parseInt(priority.current.value ? priority.current.value : null),
            allowSingle: single.current.ariaChecked === 'true' ? 1 : 0,
            channelSize: parseInt(channelSize.current.value ? channelSize.current.value : null),
            taskCount: parseInt(taskCount.current.value ? taskCount.current.value : null),
            expireTime: expireTime.current.input.value ? expireTime.current.input.value : null,
        }
        console.log(data)

        axios({
            url: '/api/cca/update',
            method: 'post',
            headers: { token: token },
            data,
        }).then(res => {
            if (res.data.code === 200) {
                console.log('ok')
                window.location.reload()
            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });

    }

    // 重置客户端某频道类型配置项下所有登记的频道的任务提交数量
    const getCcaReset = (id) => {
        axios({
            url: '/api/cca/reset',
            method: 'get',
            headers: { token: token },
            params: { id }
        }).then(res => {
            if (res.data.code === 200) {
                console.log('ok')
                window.location.reload()

            } else {
                console.log('no ok')
            }
        }, err => {
            console.log(err);
        });
    }


    // 获取客户端频道类型的配置项列表
    useEffect(() => {
        aa();
        isDisabled()
    }, [props, modifyopen]) // 当props发生变化的时候才调用这个函数，也就是点击“配置”按钮的时候调用




    return (
        <>
            <Card
                style={{
                    width: '100%',
                }}
                title={props.record.name}
                tabList={tabListNoTitle}
                activeTabKey={activeTabKey2}
                extra={<>
                    <Select
                        defaultValue="新增配置信息"
                        style={{
                            width: 130,
                        }}

                        onChange={handleChange}
                        onClick={(event) => { event.stopPropagation(); isDisabled() }}
                        options={[
                            {
                                value: '1',
                                label: '抖音',
                                disabled: tikTokDisabled,
                            },
                            {
                                value: '3',
                                label: '公众号',
                                disabled: publicAccountDisabled,

                            },
                            {
                                value: '4',
                                label: '视频号',
                                disabled: videoDisabled,
                            },
                            {
                                value: '5',
                                label: '学习强国',
                                disabled: learningPowerDisabled,
                            },
                        ]}
                    />
                    <Button onClick={(event) => { event.stopPropagation(); getCcaInsert(ccaInsertData); }} >确定增加</Button>

                </>}
                onTabChange={onTab2Change}

            >
                {contentListNoTitle[activeTabKey2]}
            </Card>
        </>
    )
}