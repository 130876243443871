import { Button, Checkbox, Form, Input } from 'antd';
import { useState, useRef } from 'react';
import { Link } from "react-router-dom"

import { getLogin } from '../../Utils/axios';





export default function Login(props) {


    const onFinishFailed = () => {
        console.log('ok')
    }
    const submit = () => {
        const data = {
            account: account.current.input.value,
            password: password.current.input.value
        }
        getLogin(data)
    }
    const account = useRef(null);
    const password = useRef(null);


    return (
        <Form
            name="basic"
            labelCol={{
                span: 8,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
            }}
            initialValues={{
                remember: true,
            }}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input ref={account} />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password ref={password} />
            </Form.Item>

            <Form.Item
                name="remember"
                valuePropName="checked"
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    offset: 8,
                    span: 16,
                }}
            >
                {/* <Link to="/client"  > */}
                <Button type="primary" htmlType="submit" onClick={submit} >
                    登录
                </Button>
                {/* </Link> */}
            </Form.Item>
        </Form>
    )
}