import axios from 'axios';


export const getLogin = (data) => {
    axios({
        url: '/api/user/login',
        method: 'post',
        data,
    }).then(res => {
        if (res.data.code === 200) {
            console.log(res.data.data.token)
            const token1 = res.data.data.token
            localStorage.setItem('token', token1)
            window.location.href = window.location.origin + '/client'
        } else {
            alert(res.data.message)
        }
    }, err => {
        console.log(err);
    });
}

export const refreshToken = () => {
    const token = localStorage.getItem('token')
    axios({
        url: '/api/user/refreshToken',
        method: 'get',
        headers: { token: token },
    }).then(res => {
        if (res.data.code === 200) {
            const newToken = res.data.data
            console.log('新token', newToken)
            localStorage.setItem('token', newToken)
        } else {
            console.log('no ok')
        }
    }, err => {
        console.log(err);
    });
}
